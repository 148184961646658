import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { StripeCheckoutButton } from "../components/stripe-checkout-button"

import "./pay.css"

const asDollars = n => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(n)
}

const IndexPage = ({ pageContext }) => {
  const { priceData, pagePath } = pageContext

  const active = priceData.active && priceData.product.active

  return (
    <Layout>
      <SEO title="Payment" />
      <h1>{priceData.product.name} - Payment Page</h1>
      {active ? (
        <div className="container pay" style={{ flex: 1 }}>
          <p>
            Amount:{" "}
            <span className="price">
              {asDollars(priceData.unit_amount / 100)}
            </span>
          </p>
          <p>Select one of the following payment methods to proceed</p>
          <div style={{ display: "flex", gridGap: 20 }}>
            <div
              style={{
                flex: 1,
                border: "1px solid black",
                textAlign: "center",
                borderRadius: 10,
                padding: 20,
              }}
            >
              <h2>Credit Card</h2>
              <StripeCheckoutButton
                pagePath={pagePath}
                priceId={priceData.id}
                text="Checkout"
              />
            </div>

            <div
              style={{
                flex: 1,
                border: "1px solid black",
                textAlign: "center",
                borderRadius: 10,
                padding: 20,
              }}
              className="paypal-section"
            >
              <h2>PayPal</h2>
              {/* Begin PayPal button */}
              <form
                action="https://www.paypal.com/cgi-bin/webscr"
                method="post"
                target="_top"
              >
                <input type="hidden" name="cmd" value="_xclick" />
                <input
                  type="hidden"
                  name="business"
                  value="henry@creativetrains.ca"
                />
                <input type="hidden" name="lc" value="US" />
                <input
                  type="hidden"
                  name="item_name"
                  value={priceData.product.name}
                />
                <input
                  type="hidden"
                  name="amount"
                  value={priceData.unit_amount / 100}
                />
                <input
                  type="hidden"
                  name="currency_code"
                  value={priceData.currency.toUpperCase()}
                />
                <input type="hidden" name="button_subtype" value="services" />
                <input type="hidden" name="no_note" value="0" />
                <input type="hidden" name="tax_rate" value="0.000" />
                <input type="hidden" name="shipping" value="0.00" />
                <input
                  type="hidden"
                  name="bn"
                  value="PP-BuyNowBF:btn_paynow_LG.gif:NonHostedGuest"
                />
                <input
                  type="image"
                  src="https://www.paypalobjects.com/en_US/i/btn/btn_paynow_LG.gif"
                  border="0"
                  name="submit"
                  alt="PayPal - The safer, easier way to pay online!"
                />
                <img
                  alt=""
                  border="0"
                  src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                  width="1"
                  height="1"
                />
              </form>
              {/* End PayPal button */}
            </div>
          </div>
        </div>
      ) : (
        <div className="container" style={{ flex: 1 }}>
          <p>This payment link has expired.</p>
        </div>
      )}
    </Layout>
  )
}

export default IndexPage
