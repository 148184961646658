import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { loadStripe } from "@stripe/stripe-js"

const buttonStyles = {
  fontSize: "16px",
  textAlign: "center",
  color: "#fff",
  backgroundColor: "#6a7879",
  padding: "0 16px",
  borderRadius: "35px",
  display: "inline-block",
  border: "3px solid transparent",
  fontFamily: "Jost, sans-serif",
  lineHeight: "1.5em",
  fontWeight: "bold",
  width: "max-content",
  margin: "0 auto",
  cursor: "pointer",
}

const buttonDisabledStyles = {
  opacity: "0.5",
  cursor: "not-allowed",
}

let stripePromise

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY)
  }
  return stripePromise
}

export const StripeCheckoutButton = ({
  pagePath,
  priceId,
  text = "Checkout",
}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  const siteUrl = data.site.siteMetadata.siteUrl

  const [loading, setLoading] = useState(false)
  const redirectToCheckout = async event => {
    event.preventDefault()
    setLoading(true)
    const stripe = await getStripe()
    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [{ price: priceId, quantity: 1 }],
      successUrl: `${siteUrl}/pay/success`,
      cancelUrl: `${siteUrl}${pagePath}?cancel=true`,
      shippingAddressCollection: {
        allowedCountries: ["CA", "US"],
      },
    })
    if (error) {
      console.warn("Error:", error)
      setLoading(false)
    }
  }

  return (
    <button
      disabled={loading}
      style={
        loading ? { ...buttonStyles, ...buttonDisabledStyles } : buttonStyles
      }
      onClick={redirectToCheckout}
    >
      {text}
    </button>
  )
}
